import React, { Component } from 'react'
import { NavLink } from 'react-router-dom'
import { ApiService } from '../../service/Api.js'

class Header extends Component {
	constructor (props) {
		super(props)

		this.state = {
			activeStyle: {
				color: '#e81341'
			}
		}
	}

	render () {
		return (
			<header className="header">
				<img className="logo" src={`${ApiService.Public}/images/logo.png`} alt=""/>
				<menu className="menu">
					<NavLink className="menu-item" activeStyle={this.state.activeStyle} exact to="/">首页</NavLink>
					<NavLink exact className="menu-item" activeStyle={this.state.activeStyle} to="/article">文章</NavLink>
					<NavLink className="menu-item" activeStyle={this.state.activeStyle} to="/thought">想法</NavLink>
					<a className="menu-item" href={`${ApiService.sso}/about.html`} target="_blank" rel="noopener noreferrer">关于</a>
					{/* <NavLink className="menu-item" activeStyle={this.state.activeStyle} to="/about">关于</NavLink> */}
				</menu>
			</header>
			)
	}
}


export default Header