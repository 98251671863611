export default {
	getItem (key) {
		return JSON.parse(sessionStorage.getItem(key)) || ''
	},

	setItem (key, value) {
		if (typeof value === 'string') {
			sessionStorage.setItem(key, value)
			return
		}
		sessionStorage.setItem(key, JSON.stringify(value))
	},

	clear () {
		sessionStorage.clear()
	}
}