import React, { Component } from 'react'
import Toast from '../../../components/common/Toast.jsx'

import '../../../style/admin/setting.css'

class AdminSetting extends Component {
  constructor(props) {
    super(props)

    this.state = {
      classifyData: [{
        label: '测试',
        value: 0
      }]
    }

    this.keyEnter = this.keyEnter.bind(this)
    this.addClassify = this.addClassify.bind(this)
    this.delClassify = this.delClassify.bind(this)
  }

  componentWillMount() {
    window
      .Fetch({
        url: '/blog/classify',
        method: 'get'
      })
      .then(res => {
        this.setState({
          classifyData: res.data
        })
      })
      .catch(() => { })
  }

  keyEnter(e) {
    if (e.keyCode === 13) {
      this.addClassify(e.target.value)
    }
  }

  addClassify(value) {
    window
      .Fetch({
        url: '/blog/classify',
        method: 'post',
        body: {
          label: value
        }
      })
      .then(res => {
        Toast.success(res.message);
        this.setState(prevState => {
          return { classifyData: [res.data, ...prevState.classifyData] };
        });
        document.querySelector('.setting-classify-input').value = ''
      })
      .catch(() => { })
  }

  delClassify(e, item) {
    window
      .Fetch({
        url: '/blog/classify',
        method: 'delete',
        body: {
          value: item.value
        }
      })
      .then(res => {
        this.setState(prevState => {
          return {
            classifyData: prevState.classifyData.filter(classify => {
              return classify.value !== item.value;
            })
          }
        })
        Toast.success(res.message)
      })
  }

  renderClassifyList() {
    return this.state.classifyData.length > 0 ?
      this.state.classifyData.map(item => {
        return <li className="setting-classify-item" key={item.value}>
          <span>{item.label}</span>
          <i className="icon-close" onClick={(e) => { this.delClassify(e, item) }}>✖</i>
        </li>
      }) :
      <li>还木有添加分类哦。。。</li>
  }

  render() {
    return <div className="setting-body">
      <div>
        <h3>分类</h3>
        <ul className="insert setting-classify-items">
          {this.renderClassifyList()}
        </ul>
        <div className="setting-classify-form">
          <input className="setting-classify-input" type="text" placeholder="classify name" onKeyDown={this.keyEnter} />
          <button className="setting-classify-btn" type="button" onClick={(e) => { this.addClassify(e.target.previousSibling.value); }}>
            添加
            </button>
        </div>
      </div>
    </div>;
  }
}

export default AdminSetting