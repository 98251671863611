import React, { Component } from 'react'
import { NavLink } from 'react-router-dom'

import Event from '../../service/events.js'

class AdminMenu extends Component {
  constructor(props) {
    super(props);

    this.state = {
      hidden: false,
      activeStyle: {
        color: '#e81341'
      }
    };
  }

  componentDidMount() {
    Event.on("fullscreen", () => {
			this.setState({
        hidden: this.state.hidden ? false : true
      });
    });
	}
	
	componentDidUpdate() {
		// this.state.hidden && this.toggleFullScreen();
	}

  toggleFullScreen() {
    if (
      !document.fullscreenElement && // alternative standard method
      !document.mozFullScreenElement &&
      !document.webkitFullscreenElement
    ) {
      // current working methods
      if (document.documentElement.requestFullscreen) {
        document.documentElement.requestFullscreen();
      } else if (document.documentElement.mozRequestFullScreen) {
        document.documentElement.mozRequestFullScreen();
      } else if (document.documentElement.webkitRequestFullscreen) {
        document.documentElement.webkitRequestFullscreen(
          Element.ALLOW_KEYBOARD_INPUT
        );
      }
    } else {
      if (document.cancelFullScreen) {
        document.cancelFullScreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitCancelFullScreen) {
        document.webkitCancelFullScreen();
      }
    }
  }

  render() {
    return <div className="admin-menu" hidden={this.state.hidden}>
        <ul className="admin-menu-list">
          <li className="admin-menu-item">
            <NavLink activeStyle={this.state.activeStyle} exact to="/admin">
              概览┏ (゜ω゜)=☞
            </NavLink>
          </li>
          <li className="admin-menu-item">
            <NavLink activeStyle={this.state.activeStyle} to="/admin/thought">
              想法o(*￣▽￣*)o
            </NavLink>
          </li>
          <li className="admin-menu-item">
            <NavLink activeStyle={this.state.activeStyle} to="/admin/article">
              文章&nbsp;&nbsp;U•ェ•*U
            </NavLink>
          </li>
          <li className="admin-menu-item">
            <NavLink activeStyle={this.state.activeStyle} to="/admin/write">
              开干&nbsp;(。・∀・)ノ
            </NavLink>
          </li>
          <li className="admin-menu-item">
            <NavLink activeStyle={this.state.activeStyle} to="/admin/setting">
              设置（＞人＜；）
            </NavLink>
          </li>
        </ul>
      </div>;
  }
}

export default AdminMenu