import React, { Component } from 'react'

import Toast from '../../components/common/Toast.jsx'

import '../../style/page.css'

class Page extends Component {
  constructor(props) {
    super(props)

    this.pageSize = this.props.pageSize || 10
  }

  // computed --start
  pageCount () {
    return Math.ceil(this.props.count / this.pageSize)
  }
  // computed --end

  // methods --start
  changePage (index) {
    this.props.change(index)
  }

  jumpPage () {
    let jumpPageIndex = this.refs.jumpPageIndex.value
    if (jumpPageIndex <= this.pageCount() && jumpPageIndex > 0) {
      this.changePage(jumpPageIndex)
      return
    }

    Toast.warning('输入的数值不合法！')
  }

  showPageItem (index) {
    if (this.pageCount() < 10) {
      return false
    }

    if ((this.props.pageIndex < 5 && index < 10) || (this.props.pageIndex > this.pageCount() - 5 && index > this.pageCount() - 10)) {
      return false
    }

    if (Math.abs(index - this.props.pageIndex) < 5) {
      return false
    }

    return true
  }
  // methods --end
 
  renderPageItem () {
    let doms = []
    for (let i = 1; i <= this.pageCount(); i++ ) {
      doms.push(
        <li role="button"
            className={`${this.props.pageIndex === i && 'active'} page-item`}
            key={i}
            hidden={this.showPageItem(i)}
            onClick={() => this.changePage(i)}>{i}</li>
      )
    }
    return doms.map(dom => { return dom })
  }

  render () {
    return <div className="page-box">
      <ul className="page-body">
        <li role="button"
            className={`${this.props.pageIndex === 1 && 'disabled'} page-item`}
            onClick={() => this.changePage(1)}>首页</li>
        <li role="button"
            className={`${this.props.pageIndex === 1 && 'disabled'} page-item`}
            onClick={() => this.changePage(this.props.pageIndex - 1)}>上一页</li>

        {this.renderPageItem()}

        <li role="button"
            className={`${this.props.pageIndex * this.pageSize >= this.props.count && 'disabled'} page-item`}
            onClick={() => this.changePage(this.props.pageIndex + 1)}>下一页</li>
        <li role="button"
            className={`${this.props.pageIndex * this.pageSize >= this.props.count && 'disabled'} page-item`}
            onClick={() => this.changePage(this.pageCount())}>尾页</li>

        <li className="page-item">
          <span>{`共${this.pageCount()}页  `}</span>
          <input ref="jumpPageIndex" type="number"/>
        </li>

        <li role="button"
            className={`${this.pageSize >= this.props.count && 'disabled'} page-item`}
            onClick={() => this.jumpPage()}>跳转</li>
      </ul>
    </div>
  }
}

export default Page