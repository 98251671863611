import React, { Component } from 'react'
import { Link } from 'react-router-dom'

import ThoughtList from '../../components/common/thoughtList'

class Index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      list: [],
      classifyData: []
    };
  }

  async componentDidMount() {
    await this.getClassify()
    await this.getArticle()
  }

  async getArticle() {
    await window
      .Fetch({
        url: "/blog/article",
        method: "get",
        query: {
          pageSize: 10,
          pageIndex: 1
        }
      })
      .then(res => {
        this.setState(() => {
          let arr = res.data.data.map(item => {
            let filter = this.state.classifyData.filter(classify => {
              return item.classify === classify.value;
            });

            item.classifyName = filter[0].label;
            return item;
          });

          return { list: arr, count: res.data.count };
        });
      })
      .catch(() => { })
  }

  async getClassify() {
    await window
      .Fetch({
        url: "/blog/classify",
        method: "get"
      })
      .then(res => {
        this.setState({
          classifyData: res.data
        })
      })
      .catch(() => { })
  }

  renderArticleList() {
    return this.state.list.map((item, key) => {
      return (
        <li className="article-item" key={key}>
          <Link to={{ pathname: `/article/detail/${item._id}` }}>
            <h3 className="article-title">{item.title}</h3>
          </Link>
          <p className="article-content">{item.content}</p>
          <div className="article-footer">
            <span>
              &: <a href="javascript:;">{item.classifyName}</a>
            </span>
            <span title="浏览">👁 {item.readed}</span>
            <span title="赞同">❤ {item.applaud}</span>
            <span title="评论">🗨 {item.discuss.length}</span>
            <span>{new Date(item.createTime).toLocaleDateString()}</span>
          </div>
        </li>
      );
    });
  }

  render() {
    return (
      <div className="index-container">
        <div className="article-container">
          <ul className="article-list">{this.renderArticleList()}</ul>
        </div>
        <div className="thought-container home-thought">
          <ThoughtList pageIndex="1" pageSize="2"></ThoughtList>
        </div>
      </div>
    );
  }
}

export default Index