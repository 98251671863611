import React, { Component } from 'react'
import { Route } from 'react-router-dom'

import AdminMenu from './menu'
import AdminOverview from "./overview/overview";
import AdminThought from "./thought";
import AdminArticleList from './articleList'
import AdminWrite from './write/write'
import AdminSetting from './setting/setting'

class AdminBody extends Component {
  constructor(props) {
    super(props);

    this.state = {
			display: 'block'
    }
  }

  componentDidMount () {
  }

  render() {
    return <div className="admin-body">
        <AdminMenu />
        <div className="admin-content">
          <Route exact path="/admin" component={AdminOverview} />
          <Route path="/admin/thought" component={AdminThought} />
          <Route path="/admin/article" component={AdminArticleList} />
          <Route path="/admin/write" component={AdminWrite} />
          <Route path="/admin/setting" component={AdminSetting} />
        </div>
      </div>;
  }
}

export default AdminBody