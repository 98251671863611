import React, { Component } from 'react'
import AdminHeader from './header'
import AdminBody from './body'
import storage from '../../service/storage'
import '../../style/admin/index.css'

class AdminIndex extends Component {
	constructor (props) {
		super(props)
		this.state = {}
	}

	componentWillMount () {
		if (!storage.getItem('user')) {
			window
				.Fetch({
					url: "/login",
					method: "POST",
					body: {
						username: "nihaoa",
						password: "nihaoa"
					}
				})
				.then(res => {
					storage.setItem("user", res.data);
				})
				.catch(err => { });
		}
	}

	render () {
		return (
			<div className="App">
				<AdminHeader />
				<AdminBody />
			</div>
			)
	}
}

export default AdminIndex