import React, { Component } from 'react'
import Marked from '../../base/highlight/marked.js'
import Toast from '../../components/common/Toast.jsx'

import '../../style/markdown.css'
import "../../base/highlight/styles/arta.css";
import '../../style/article.css'

class ArticleDetail extends Component {
  constructor(props) {
    super(props);

    this.state = {
      info: {
        discuss: []
      },
      color: "inherit"
    };

    this.getArticleDetail = this.getArticleDetail.bind(this);
    this.addApplaud = this.addApplaud.bind(this);
  }

  componentDidMount() {
    this.getArticleDetail();

    // 判断是否点赞过
    if (this.isAddApplaud()) {
      this.setState({
        color: "#f56726"
      });
    }

    // 判断是否存在评论人
    let discussant = window.localStorage.getItem('discussant')
    if (!!discussant) {
      document.querySelector('.discussant').value = discussant
    }
  }

  // 获取文章信息
  getArticleDetail() {
    let id = this.props.match.params.id;
    if (!id) return;

    window
      .Fetch({
        url: `/blog/article/${id}`,
        method: "get"
      })
      .then(res => {
        res.data.content = Marked(res.data.content);

        this.setState({
          info: res.data
        });

        this.refs.result.innerHTML = res.data.content;
      })
      .catch(() => { });
  }

  // 点赞
  addApplaud() {
    if (this.isAddApplaud()) {
      Toast.info("已经赞过了哦（。＾▽＾）");
      return false;
    }

    window
      .Fetch({
        url: "/blog/applaud",
        method: "post",
        body: {
          id: this.state.info._id
        }
      })
      .then(res => {
        let applaud = JSON.parse(
          window.localStorage.getItem("applaud") || "[]"
        );

        applaud.push(this.state.info._id);
        this.setState({
          color: "#f56726"
        });

        window.localStorage.setItem("applaud", JSON.stringify(applaud));

        Toast.success("点赞成功！");
      })
      .catch(() => { });
  }

  // 是否是点赞过的文章
  isAddApplaud() {
    let applaud = JSON.parse(window.localStorage.getItem("applaud") || "[]");

    return applaud.indexOf(this.state.info._id) !== -1;
  }

  // 添加评论
  addDiscuss() {
    let discussant = document.querySelector('.discussant').value,
      content = this.refs.discuss.innerText
    window
      .Fetch({
        url: '/blog/discuss',
        method: 'post',
        body: {
          id: this.state.info._id,
          discussant: discussant,
          content: content
        }
      })
      .then(res => {
        this.setState(prevState => {
          let info = Object.assign({}, prevState.info)

          info.discuss.push({
            _id: Math.random(),
            discussant: discussant,
            content: content,
            createTime: Date.now()
          })
          return {
            info: info
          }
        })

        window.localStorage.setItem('discussant', discussant)
        Toast.success('评论成功！d=====(￣▽￣*)b')
      })
      .catch(() => { })
  }

  // 渲染评论列表
  renderDiscuss() {
    return this.state.info.discuss.map(discuss => {
      return <li className="discuss-item" key={discuss._id}>
        <span>{discuss.discussant}</span>
        <span>于 {new Date(discuss.createTime).toLocaleString()}</span>
        <p className="discuss-content">{discuss.content}</p>
      </li>
    })
  }

  render() {
    return (
      <div className="article-detail-body">
        <h3 className="article-detail-title">{this.state.info.title}</h3>
        <small className="article-detail-small">
          ❤ <span>{this.state.info.applaud}</span>
          👁 <span>{this.state.info.readed}</span>
          &: <span>{this.state.info.classifyName}</span>
          <span style={{ color: this.state.color, cursor: "pointer" }} onClick={this.addApplaud} > ❤ </span>
        </small>
        {/* 文章内容 */}
        <div ref="result" className="article-detail-content markdown-preview" />
        {/* 输入评论内容 */}
        <div className="discuss-edit">
          <input className="discussant" type="text" placeholder="这里输入昵称呦" />
          <div className="discuss-textarea" contentEditable="true" ref="discuss" placeholder="这里输入评论内容" />
          <button className="discuss-submit" onClick={() => { this.addDiscuss() }} >提交</button>
        </div>
        {/* 评论区 */}
        <ul className="discuss-items">
          {this.renderDiscuss()}
        </ul>
      </div>
    );
  }
}

export default ArticleDetail