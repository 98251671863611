import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import storage from './service/storage'

import './index.css';

import Toast from './components/common/Toast.jsx'
import UserIndex from './components/user/index.jsx';
import AdminIndex from './components/admin/index';
import registerServiceWorker from './registerServiceWorker';

window.Fetch = (config) => {
	let user = storage.getItem('user') || {}
	// 设置url base
	!config.noapi && (config.url = `/api${config.url}`)

	// 增加请求头
	config.headers = Object.assign({
		'Authorization': user.token || ''
	}, config.headers || {})

	if (config.method.toLowerCase() === 'post') {
		// 增加请求头
		config.headers = Object.assign({
			'Accept': 'application/json',
			'Content-Type': 'application/json'
		}, config.headers || {})

		// 序列化body
		!!config.body && (config.body = JSON.stringify(config.body))
	}

	// 将上传文件的post和普通post区分开，以备序列化
	if (config.method.toLowerCase() === 'upload') {
		config.method = 'post'
		config.url = `https://files.${process.env.REACT_APP_DOMAIN}${config.url}`
	}

	if (!!config.query) {
		let arr = []

		let keys = Object.keys(config.query)

		keys.forEach(key => {
			arr.push(`${key}=${config.query[key]}`)
		})

		config.url = `${config.url}?${arr.join('&')}`
	}

	// eslint-disable-next-line
	return fetch(config.url, config)
		.then(res => {
			if (!res.ok) {
				Toast.error(res.statusText)
				return Promise.reject(res.statusText)
			} else {
				return res.json()
			}
		})
		.then(res => {
			if (res.result === 1) {
				return res
			}

			Toast.error(res.message)
			return Promise.reject(res.message)
		})
		.catch(err => { Toast.error(err.message) })
}

ReactDOM.render(
	<Router>
		<Switch>
			<Route path="/admin" component={AdminIndex}></Route>
			<Route path="/" component={UserIndex}></Route>
		</Switch>
	</Router>
	, document.getElementById('root'));

registerServiceWorker();
