import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { ApiService } from '../../service/Api.js'


class AdminHeader extends Component {
	constructor (props) {
		super(props)

		this.state = {
			display: false
		}
	}

	componentDidMount () {
		document.addEventListener('fullscreen', () => {
			this.setState({
				hidden: this.state.hidden ? false : true
			})
		})
	}


	render () {
		return <div hidden={this.state.hidden}>
        <div className="header">
          <Link to="/">
            <img className="logo" src={`${ApiService.Public}/images/logo.png`} alt="" />
          </Link>
        </div>
      </div>;
	}
}

export default AdminHeader