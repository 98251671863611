import React, { Component } from 'react'
// import { Link } from 'react-router-dom'

import Toast from '../../components/common/Toast.jsx'
import ThoughtList from '../../components/common/thoughtList.jsx'

import Event from '../../service/events'

import '../../style/thought.css'

class AdminThought extends Component {
  constructor(props) {
    super(props)

    this.state = {
      list: []
    }
  }

  componentWillMount() {
  }

  addThought(e) {
    window
      .Fetch({
        url: '/blog/thought',
        method: 'post',
        body: {
          content: this.refs.editor.innerText
        }
      })
      .then(res => {
        console.log(e)
        Event.emit(document.querySelector('.thought-tools button'), 'refresh')
        this.refs.editor.innerText = ''
        Toast.success(res.message)
      })
      .catch(() => { })
  }


  render() {
    return (
      <div className="admin-thought">
        <div>
          <div className="thought-editor" ref="editor" contentEditable="true"></div>
          <div className="thought-tools">
            <button type="button" onClick={(e) => { this.addThought(e) }}>发表</button>
          </div>
        </div>
        <ThoughtList></ThoughtList>
      </div>
    )
  }
}

export default AdminThought