import React, { Component } from 'react'
import ReactDom from 'react-dom'

import '../../style/notification.css'

import Notice from './Notice.jsx'

class Notification extends Component {
  constructor (props) {
    super(props)

    this.state = {
      notices: [],
      hasMask: true
    }

    this.renderNotices = this.renderNotices.bind(this)
  }

  add (notice) {
    this.setState(prevState => {
      notice.key = `notification-${Date.now()}`
      prevState.notices.push(notice)

      return {
        notices: prevState.notices,
        hasMask: notice.mask || prevState.hasMask
      }
    })
  }

  remove (notice) {
    this.setState(prevState => {
      return {
        notices: prevState.notices.filter(notic => { return notic.key !== notice.key })
      }
    })
  }

  renderMask () {
    if (!!this.state.hasMask && this.state.hasMask.length > 0) 
      return <div className="notification-mask"></div>
  }

  renderNotices () {
    let This = this

    return this.state.notices.map(notice => {
      const closeCallback = () => {
        This.remove(notice.key)

        if (notice.onClose) notice.onClose()
      }

      return <Notice key={notice.key} {...notice} onClick={closeCallback} />;
    })
  }

  render () {
    return (
      <div className="notification-box">
        { this.renderMask() }
        { this.renderNotices() }
      </div>
    )
  }
}

Notification.reWrite = (properties) => {
  const {...props} = properties || {}

  let div;

  div = document.createElement('div')

  document.body.appendChild(div)

  const notification = ReactDom.render(<Notification {...props}></Notification>, div)

  return {
    notice (noticeProps) {
      notification.add(noticeProps)
    },
    remove (noticeProps) {
      notification.remove(noticeProps)
    },
    destroy () {
      ReactDom.unmountComponentAtNode(div)
      document.body.removeChild(div)
    },
    component: notification
  }
}

export default Notification