import React, { Component } from 'react'

import Event from '../../service/events'

import '../../style/thought.css'

class ThoughtList extends Component {

  constructor(props) {
    super(props)
    this.state = {
      list: [],
      count: 0,
      pageIndex: 1,
      pageSize: 10,
    }
  }

  componentWillMount() {
    this.getThought()

    Event.on('refresh', () => {
      this.getThought()
    })
  }

  getThought() {
    let query = {}
    if (this.props.pageIndex) {
      query = {
        pageIndex: this.props.pageIndex,
        pageSize: this.props.pageSize
      }
    }
    window
      .Fetch({
        url: '/blog/thought',
        method: 'get',
        query: query
      })
      .then(res => {
        this.setState({
          list: res.data.data,
          count: res.data.count
        })
      })
      .catch(() => { })
  }

  goDetail(e) {

  }

  renderThoughtList() {
    return this.state.list.map(item => {
      return <li role="button" className="thought-item"
        style={{ backgroundColor: `#${(~~(Math.random() * (1 << 24))).toString(16)}99` }} key={item._id}
        onClick={(e) => { this.goDetail(e) }}>
        <p className="thought-item-content">{item.content}</p>
        <span className="thought-item-time">{new Date(+item.createTime).toLocaleString()}</span>
      </li>
    })
  }

  render() {
    return (
      <ul className="thought-list">
        {this.renderThoughtList()}
      </ul>
    )
  }
}

export default ThoughtList