import React, { Component } from 'react'

import ThoughtList from '../../components/common/thoughtList.jsx'

class Thought extends Component {

	constructor (props) {
		super(props)
		this.state = {
		}
	}

	componentWillMount () {
  }

	render () {
		return (
			<div className="user-thougth">
				<ThoughtList></ThoughtList>
			</div>
			)
	}
}

export default Thought