const origin = 'https://'

const Public = `${origin}public.${process.env.REACT_APP_DOMAIN}`
const sso = `${origin}sso.${process.env.REACT_APP_DOMAIN}`
const file = `${origin}file.${process.env.REACT_APP_DOMAIN}`

export let ApiService = {
	Public,
	sso,
	file
}