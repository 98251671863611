import React, { Component } from 'react'
// import Upload from '../../components/common/Upload.jsx'

class About extends Component {
	constructor (props) {
		super(props)
		this.state = {

		}
	}

	render () {
		return (
			<div>
				<div>
					<h3>关于我</h3>
					<p></p>
				</div>
			</div>
			)
	}
}

export default About