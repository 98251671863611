import React, { Component } from 'react'

class AdminOverview extends Component {
	constructor (props) {
		super(props)
		this.state = {}
	}

	render () {
		return (
			<div className="admin-content">
				admin-content
			</div>
			)
	}
}

export default AdminOverview