// Notice是Toast最底层组件
// 每个黑色的小框框其实都是一个Notice
// Notice核心就是组件初始化的时候 生成一个定时器
// 根据输入的时间 加载一个动画 然后执行输入的回调
// Notice的显示和隐藏收到父组件Notification的绝对控制
import React, { Component } from "react";
import classNames from "classnames";

class Notice extends Component {
  // static propTypes = { 
  //   duration: React.PropTypes.number, // Notice显示时间
  //   content: React.PropTypes.any, // Notice显示的内容
  //   onClose: React.PropTypes.func // 显示结束回调
  // };
  static defaultProps = {
    duration: 3000
  };
  
  constructor(props) {
    super(props);
    this.state = {
      shouldClose: false, // 是否开启关闭动画
      display: 'block'
    };
  }

  componentDidMount() {
    if (this.props.duration > 0) {
      this.closeTimer = setTimeout(() => {
        this.close();
      }, this.props.duration - 300); // 减掉消失动画300毫秒
    }
  }

  componentWillUnmount() {
    // 当有意外关闭的时候 清掉定时器
    this.clearCloseTimer();
  }

  clearCloseTimer() {
    if (this.closeTimer) {
      clearTimeout(this.closeTimer);
      this.closeTimer = null;
    }
  }

  close() {
    // 关闭的时候 应该先清掉倒数定时器
    // 然后开启过场动画
    // 等待动画结束 执行回调
    this.clearCloseTimer();

    const _this = this;

    _this.setState({ shouldClose: true });

    this.timer = setTimeout(() => {
      _this.setState({ display: 'none' })

      if (this.props.onClose) {
        this.props.onClose();
      }

      clearTimeout(_this.timer);
    }, 300);
  }

  render() {
    const { shouldClose, display } = this.state;

    return (
      <div className={classNames(["zby-notice-box", { leave: shouldClose }])} style={{display: display}}>
        {this.props.content}
      </div>
    );
  }
}

export default Notice
