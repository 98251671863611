import "../../../style/markdown.css";
import "../../../style/admin/write.css";
import "../../../base/highlight/styles/arta.css"

import storage from '../../../service/storage'
import Event from '../../../service/events'
import React, { Component } from 'react'
import Marked from '../../../base/highlight/marked.js'
import { ApiService } from '../../../service/Api.js'

import Toast from '../../../components/common/Toast.jsx'
import Upload from '../../../components/common/Upload.jsx'

class AdminWrite extends Component {
	constructor(props) {
		super(props)

		this.state = {
			text: '',
			classifyData: []
		}

		this.timer = null
		this.editorScroll = false
		this.resultScroll = false

		this.input = this.input.bind(this)
		this.submit = this.submit.bind(this)

		this.resultScrollFn = this.resultScrollFn.bind(this)
		this.editorScrollFn = this.editorScrollFn.bind(this)
	}

	componentDidMount() {
		this.getClassify()
		this.addScrollEvent()

		if (this.props.match.params.id) {
			this.getAriticalDetail(this.props.match.params.id)
		}
	}

	componentDidUpdate() {
		this.refs.result.scrollTop = this.refs.editor.scrollTop
	}

	getAriticalDetail(id) {
		window
			.Fetch({
				url: `/blog/article/${id}`,
				method: "get"
			})
			.then(res => {
				res.data.content = Marked(res.data.content);

				window.writeForm.title.value = res.data.title
				this.refs.editor.innerText = res.data.content
				window.writeForm.classify.value = res.data.classify

				this.setState({
					text: res.data.content
				});

				// this.refs.result.innerHTML = res.data.content;
			})
			.catch(() => { });
	}

	getClassify() {
		window
			.Fetch({
				url: "/blog/classify",
				method: "get"
			})
			.then(res => {
				this.setState({
					classifyData: res.data
				});
			});
	}

	addScrollEvent() {
		this.refs.result.addEventListener('scroll', this.resultScrollFn)
		this.refs.editor.addEventListener('scroll', this.editorScrollFn)
	}

	removeScrollEventForResult() {
		this.refs.result.removeEventListener('scroll', this.resultScrollFn)
		this.refs.editor.removeEventListener('scroll', this.editorScrollFn)
	}

	resultScrollFn() {
		this.resultScroll = true
		if (!this.editorScroll) {
			this.refs.editor.scrollTop = (this.refs.editor.scrollHeight - this.refs.editor.offsetHeight) * (this.refs.result.scrollTop / (this.refs.result.scrollHeight - this.refs.result.offsetHeight))
		}
		this.editorScroll && (this.editorScroll = false)
	}

	editorScrollFn() {
		this.editorScroll = true
		if (!this.resultScroll) {
			this.refs.result.scrollTop = (this.refs.result.scrollHeight - this.refs.result.offsetHeight) * (this.refs.editor.scrollTop / (this.refs.editor.scrollHeight - this.refs.editor.offsetHeight))
		}
		this.resultScroll && (this.resultScroll = false)
	}

	// 预览
	input(e) {
		// 清除定时器
		clearTimeout(this.timer)

		// 重新设置定时器
		this.timer = setTimeout(() => {
			let text = this.refs.editor.innerText

			this.removeScrollEventForResult()
			this.refs.result.innerHTML = Marked(text)
			this.addScrollEvent()

			this.setState({
				text: text
			})

			// 清除
			clearTimeout(this.timer)
			this.timer = null
		}, 600);
	}


	submit() {
		window
			.Fetch({
				url: "/blog/article",
				method: "post",
				body: {
					userid: storage.getItem("user").user.userid,
					title: window.writeForm.title.value,
					content: this.state.text,
					classify: window.writeForm.classify.value
				}
			})
			.then(res => {
				Toast.success(res.message);
				window.writeForm.title.value = "";
				this.refs.editor.innerText = "";
				this.refs.result.innerText = "";
			})
			.catch(err => { });
	}

	fullscreen(e) {
		Event.emit(e.target, 'fullscreen')
	}

	addImages(datas) {
		datas.forEach(data => {
			this.refs.editor.innerText += `![${data.originalname}](${ApiService.file}${data.path}) \n`
		})
	}

	renderClassify() {
		return this.state.classifyData.map(classify => {
			return <option key={classify.value} value={classify.value}>{classify.label}</option>;
		})
	}

	render() {
		return (
			<form className="write-box" name="writeForm">
				<div className="write-header">
					<input type="text" name="title" maxLength="30" placeholder="标了个题 (。・∀・)ノ" />
					<div className="write-operate">
						<select className="classify" name="classify" id="classify">
							{this.renderClassify()}
						</select>

						<button type="button" className="write-submit" onClick={this.submit}>
							发表文章
						</button>

						<button type="button" className="fullscreen" title="全屏" onClick={this.fullscreen}>⚃</button>

						<Upload style={{ display: 'inline-block' }} action="/upload" accept="image/*" load={(datas) => { this.addImages(datas) }}>
							<span className="uploadimage" title="上传图片">🖼</span>
						</Upload>
					</div>
				</div>
				<div className="write-content">
					<div ref="editor" spellCheck="false" className="write-editor" contentEditable="true" onInput={this.input} placeholder="这里输入正文啊！（＞人＜；）" />
					{/* <textarea name="editor" spellcheck="false" ref="wangEditor" className="write-editor" onInput={this.input} placeholder="这里输入正文啊！（＞人＜；）" /> */}
					<div ref="result" className="write-result markdown-preview"></div>
				</div>
			</form>
		);
	}

}

export default AdminWrite