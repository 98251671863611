import React, { Component } from 'react'
import { NavLink } from 'react-router-dom'

import Page from '../../components/common/page.jsx'
import Toast from '../../components/common/Toast.jsx'

class AdminArticleList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      list: [],
      classifyData: [],
      count: 0,
      pageIndex: 1,
      pageSize: 10,
    };

    this.delete = this.delete.bind(this);
  }

  async componentDidMount() {
    await this.getClassify()
    await this.getArticle()
  }

  async getArticle() {
    await window
      .Fetch({
        url: "/blog/article",
        method: "get",
        query: {
          pageSize: this.state.pageSize,
          pageIndex: this.state.pageIndex
        }
      })
      .then(res => {
        this.setState(() => {
          let arr = res.data.data.map(item => {
            let filter = this.state.classifyData.filter(classify => {
              return item.classify === classify.value;
            });

            item.classifyName = filter[0].label;
            return item;
          });

          return { list: arr, count: res.data.count };
        });
      })
      .catch(() => { });
  }

  async getClassify() {
    await window
      .Fetch({
        url: "/blog/classify",
        method: "get"
      })
      .then(res => {
        this.setState({
          classifyData: res.data
        });
      })
      .catch(() => { });
  }

  editAriticle(id) {

  }

  delete(ids) {
    window
      .Fetch({
        url: "/blog/article",
        method: "delete",
        body: {
          ids: ids
        }
      })
      .then(res => {
        Toast.success(res.message);
        this.setState((prevState) => {
          return {
            list: prevState.list.filter(item => {
              return !ids.includes(item._id)
            })
          }
        })
      })
      .catch(() => { });
  }

  deleteOne(e, item) {
    e.preventDefault();
    e.stopPropagation();

    let ids = [item._id];
    this.delete(ids);
  }

  pageChange(index) {
    this.setState({
      pageIndex: +index
    })
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.pageIndex !== prevState.pageIndex) {
      this.getArticle()
    }
  }

  renderItems() {
    return this.state.list.map(item => {
      return (
        <NavLink
          key={item._id}
          activeStyle={{ color: "red" }}
          to={{ pathname: `/article/detail/${item._id}` }}
        >
          <li key={item._id}>
            <span>{item.title}</span>
            <span>{item.classifyName || ''}</span>
            <span>{item.readed}</span>
            <span>{item.applaud}</span>
            <span>{item.discuss.length}</span>
            <span>{new Date(item.createTime).toLocaleDateString()}</span>
            <span>
              <button
                type="button"
                className="btn btn-error btn-xs"
                onClick={e => {
                  this.deleteOne(e, item);
                }}
              >
                删除
              </button>
              <button className="btn btn-error btn-xs" onClick={item => this.editAriticle(item._id)}>编辑</button>
            </span>
          </li>
        </NavLink>
      );
    });
  }

  render() {
    let { count, pageIndex, pageSize } = this.state
    return (
      <div className="admin-article-list">
        <h2 className="admin-article-title">
          这里是列表（￣︶￣）↗
        </h2>
        <ul className="admin-article-items">{this.renderItems()}</ul>

        <Page count={count} pageIndex={pageIndex} pageSize={pageSize} change={(index) => { this.pageChange(index) }}></Page>
      </div>
    );
  }
}

export default AdminArticleList