let eventLoop = {}


export default {
  emit (element, eventName, eventType) {
    if (!!eventLoop[eventName]) {
      element.dispatchEvent(eventLoop[eventName])
      return  
    }

    let event = document.createEvent('HTMLEvents')

		event.initEvent(eventName, true, true)
		event.eventType = eventType || 'message'
    eventLoop[eventName] = event

		element.dispatchEvent(event)
  },

  on (eventName, callback) {
    document.addEventListener(eventName, (e) => {
      callback(e)
    })
  }
}