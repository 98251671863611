import React, { Component } from 'react'
import Toast from './Toast.jsx'


class Upload extends Component {
  constructor (props) {
    super(props)

    this.state = {
      fileList: []
    }
  }

  openInput () {
    this.refs.input.click()
  }

  change (e) {
    let input = e.target
    let files = input.files
    let ajaxs = []
    
    if (!files || files.length === 0) return;

    for (let i = 0; i < files.length; i++) {
      ajaxs.push(this.submit(files[i]))
    }

    Promise.all(ajaxs)
      .then((datas) => {
        this.setState({
          fileList: datas
        })
        !!this.props.load && this.props.load(datas)

        input.value = ''
        Toast.success(`${datas.length}个文件上传成功`)
      })
  }

  submit (file) {
    let formData = new FormData()

    formData.append('public', file)

    return window
      .Fetch({
        method: 'upload',
        mode: 'cors',
        url: this.props.action,
        body: formData,
        noapi: true
      })
      .then(res => {
        return res.data
      })
  }

  renderFileList () {
    if (!this.props.showList) return '';
    return <ul>
      {this.state.fileList.map(data => {
        return <li>
          <span>{data.path}</span>
        </li>
      })}
    </ul>
  }

  render () {
    const { children, multipe, accept } = this.props

    return <div style={{...this.props.style, cursor: 'pointer'}} onClick={e => this.openInput()}>
        {children}
        <input ref="input" style={{display: 'none'}} multiple={multipe || false} accept={accept || '*'} type="file" onChange={e => this.change(e)} />
      </div>
      // {this.renderFileList()}
  }
}

export default Upload