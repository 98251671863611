import React, { Component } from 'react';
import { Route } from 'react-router-dom'

import "../../style/user/index.css";

import Header from './header'
import Home from './home'
import Article from "./article";
import ArticleDetail from './article-detail'
import Thought from './thought'
import About from './about'

class UserIndex extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  componentWillMount() {
    if (process && process.env.NODE_ENV === "production") {
      document.domain = process.env.REACT_APP_DOMAIN
    }
  }

  componentDidMount() {
  }

  render() {
    return (
      <div className="App">
        <Header></Header>
        <div className="App-body">
          <Route exact path="/" component={Home}></Route>
          <Route exact path="/article" component={Article}></Route>
          <Route path="/article/detail/:id" component={ArticleDetail}></Route>
          <Route path="/thought" component={Thought}></Route>
          <Route path="/about" component={About}></Route>
        </div>
      </div>
    );
  }
}

export default UserIndex;
