import React, { Component } from 'react'
import { Link } from 'react-router-dom'

import Page from '../../components/common/page.jsx'

class Article extends Component {

	constructor(props) {
		super(props)
		this.state = {
			list: [],
			classifyData: [],
			count: 0,
			pageIndex: 1,
			pageSize: 10,
			classify: -1
		}
	}

	async componentDidMount() {
		await this.getClassify()
		await this.getArticle()
	}

	async getArticle() {
		let options = {
			url: '/blog/article',
			method: 'get',
			query: {
				pageIndex: this.state.pageIndex,
				pageSize: this.state.pageSize
			}
		}

		if (this.state.classify !== -1) {
			options.query.classify = this.state.classify
		}

		await window
			.Fetch(options)
			.then((res) => {
				this.setState(() => {
					let arr = res.data.data.map(item => {
						let filter = this.state.classifyData.filter(classify => {
							return item.classify === classify.value;
						});

						item.classifyName = filter[0].label;
						return item;
					});

					return { list: arr, count: res.data.count };
				});
			})
			.catch(() => { })
	}

	async getClassify() {
		await window
			.Fetch({
				url: '/blog/classify',
				method: 'get'
			})
			.then(res => {
				this.setState({
					classifyData: res.data
				})
			})
			.catch(() => { })
	}

	classifyChange(value) {
		value = value || -1
		this.setState({
			classify: value
		})
	}

	pageChange(index) {
		this.setState({
			pageIndex: +index
		})
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevState.pageIndex !== this.state.pageIndex || prevState.classify !== this.state.classify) {
			this.getArticle()
		}
	}

	renderClassify() {
		return this.state.classifyData.map(classify => {
			return <li className="classify-item" key={classify.value} onClick={() => this.classifyChange(classify.value)}>{classify.label}</li>
		})
	}

	renderArticleList() {
		return this.state.list.map((item, key) => {
			return <li className="article-item" key={key}>
				<Link to={{ pathname: `/article/detail/${item._id}` }}>
					<h3 className="article-title">{item.title}</h3>
				</Link>
				<p className="article-content">{item.content}</p>
				<div className="article-footer">
					<span>
						&: <a href="javascript:;">{item.classifyName}</a>
					</span>
					<span title="浏览">👁 {item.readed}</span>
					<span title="赞同">❤ {item.applaud}</span>
					<span title="评论">🗨 {item.discuss.length}</span>
					<span>{new Date(item.createTime).toLocaleDateString()}</span>
				</div>
			</li>;
		})
	}

	render() {
		let { count, pageIndex, pageSize } = this.state

		return (
			<div className="article-body">
				<aside className="classify-container">
					<h2 className="classify-title">文章分类</h2>
					<ul className="classify-list">
						<li className="classify-item" onClick={() => this.classifyChange()}>全部</li>
						{this.renderClassify()}
					</ul>
				</aside>
				<ul className="article-list">
					{this.renderArticleList()}
				</ul>
				<Page count={count} pageIndex={pageIndex} pageSize={pageSize} change={(index) => { this.pageChange(index) }}></Page>
			</div>
		)
	}
}


export default Article